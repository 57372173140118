import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { Navigate } from 'react-router';

// maintenance routing
const Landing = Loadable(lazy(() => import('views/pages/landing/index')));
const PrivacyPolicy = Loadable(lazy(() => import('views/pages/saas-pages/PrivacyPolicy')));
const TermsOfService = Loadable(lazy(() => import('views/pages/saas-pages/TermsOfService')));
const ScrollToTop = Loadable(lazy(() => import('../hooks/scrollToTop')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: (
        <>
            <ScrollToTop />
            <MinimalLayout />
        </>
    ),
    children: [
        {
            path: '/',
            element: <Landing />
        },
        {
            path: '/tos',
            element: <TermsOfService />
        },
        {
            path: '/privacy',
            element: <PrivacyPolicy />
        },
        {
            path: '*',
            element: <Navigate to="/" />
        }
    ]
};

export default AuthenticationRoutes;
