import { createBrowserRouter } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter([AuthenticationRoutes], {
    basename: process.env.REACT_APP_BASE_NAME
});
export default router;
